import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IntroImg from '../../static/img/mixed-fruits.jpg'
import JessMin from '../../static/img/jess-min.jpg'
import WholeFoodNutrition from '../../static/img/whole-food-nutrition.jpg'
import ReadTheResearch from '../../static/img/read-the-research.jpg'
import FreeForKids from '../../static/img/free-for-kids.jpg'
import UrbanFarmer from '../../static/img/urban-farmer.jpg'
import JoinOurMission from '../../static/img/join-our-mission.jpg'
import InspiringHealthyLiving from '../../static/img/inspiring-healthy-living.jpg'

const IndexPage = () => (
  <Layout>
       <header className="masthead text-center text-white" id="intro" style={{backgroundImage:`url(${IntroImg})`}}>
            <div className="masthead-content">
                 <div className="container">
                 <h1 className="masthead-heading mb-0">One Simple Change</h1>
                 <h2 className="masthead-subheading mb-0" style={{color:'#ffb502'}}>Real Nutrition. Real Simple.</h2>
               </div>
             </div>
       </header>

       <section>
           <div className="container">
             <div className="row">
               <div className="col-12 col-md-6 col-lg-4">
                <div className="card mt-4">
                  <img src={WholeFoodNutrition} className="card-img-top" />
                  <div className="card-body"><h4 className="m-0">Whole Food Nutrition</h4><p>Help add what's missing from your diet with whole food nutrition.</p></div>
                  <div className="card-footer"><a href="https://jessica-arnold.juiceplus.com/us/en" target="_blank" className="btn btn-block btn-dark text-white">Learn More</a></div>
                </div>
               </div>
               <div className="col-12 col-md-6 col-lg-4">
               <div className="card mt-4">
                  <img src={ReadTheResearch} className="card-img-top" />
                 <div className="card-body"><h4 className="m-0">Read the Research</h4><p>The most thoroughly researched nutrional product in the world.</p></div>
                 <div className="card-footer"><a href="https://online.flippingbook.com/view/319435" target="_blank" className="btn btn-block btn-dark text-white">Learn More</a></div>
               </div>
               </div>
               <div className="col-12 col-md-6 col-lg-4">
               <div className="card mt-4">
                  <img src={FreeForKids} className="card-img-top" />
                 <div className="card-body"><h4 className="m-0">FREE for Kids</h4><p>Sponsor a child for FREE whole food gummies with a qualifying Adult order!</p></div>
                 <div className="card-footer"><a href="https://jessica-arnold.juiceplus.com/us/en/get-started/healthy-starts-about" target="_blank" className="btn btn-block btn-dark text-white">Learn More</a></div>
               </div>
               </div>
               <div className="col-12 col-md-6 col-lg-4">
               <div className="card mt-4">
                <img src={InspiringHealthyLiving} className="card-img-top" />
                 <div className="card-body"><h4 className="m-0">Inspiring Healthy Living</h4><p>Learn the why's behind Fruits and Veggies and how One Simple Change can add more whole food nutrition to your diet everyday!</p></div>
                 <div className="card-footer"><a href="https://missiondriven.info/inspiring-healthy-living" target="_blank" className="btn btn-block btn-dark text-white">Learn More</a></div>
               </div>
               </div>
               <div className="col-12 col-md-6 col-lg-4">
               <div className="card mt-4">
                <img src={UrbanFarmer} className="card-img-top" />
                 <div className="card-body"><h4 className="m-0">Urban Farmer</h4><p>All-in-one system that delivers a year-round supply of health, homegrown herbs and veggies with a fraction of the time and work of a regular garden.</p></div>
                 <div className="card-footer"><a href="https://jessica-arnold.towergarden.com/" target="_blank" className="btn btn-block btn-dark text-white">Learn More</a></div>
               </div>
               </div>
               <div className="col-12 col-md-6 col-lg-4">
               <div className="card mt-4">
                <img src={JoinOurMission} className="card-img-top" />
                 <div className="card-body"><h4 className="m-0">Join Our Mission</h4><p>Passionate about health and wellness? Ask me about the financial opportunity with simply inspiring healthy living around the world.</p></div>
                 <div className="card-footer"><a href="https://jessica-arnold.juiceplusvirtualfranchise.com/the-juice-plus-business-model/business-model" target="_blank" className="btn btn-block btn-dark text-white">Learn More</a></div>
               </div>
               </div>
            </div>
          </div>
        </section>

       <section>
           <div className="container">
             <div className="row align-items-center">
               <div className="col-lg-6 order-lg-2">
                 <div className="p-5">
                   <img className="img-fluid rounded-circle" src={JessMin} alt="Jessica" />
                 </div>
               </div>
               <div className="col-lg-6 order-lg-1">
                 <div className="p-5">
                   <h2 className="display-4">A little about me...</h2>
                   <p>Hi, I'm Jess! I believe the kitchen is for dancing and creating fun, healthy dishes that bring family and friends together. I'm passionate about sharing how fruits and veggies have awesome healing powers that can change your health! Being a mother of three (which includes a high-energy black lab fur-baby) having less toxins in the home is important and I'm inspired to share these tips with you. Cheers to juicy, healthy happiness!</p>
                 </div>
               </div>
             </div>
           </div>
         </section>
      <section>
          <div className="container mt-5" id="connect">
               <div className="card text-white bg-dark mb-3">
               <div className="card-header"><h2>Whole Food Cookbook</h2></div>
               <div className="card-body">
                 <h5 className="card-title">Download yours for fresh inspiration today!</h5>
                 <p className="card-text">
                      <div className="form">
                           <form name="contact" action="/success" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                               <input type="hidden" name="form-name" value="contact" />
                               <input type="hidden" name="bot-field" />
                               <div className="form-group">
                                    <input type="text" name="name" className="form-control form-control-lg" placeholder="Your Name" required/>
                               </div>
                               <div className="form-group">
                                    <input type="email" className="form-control form-control-lg" name="email" placeholder="Your Email" required/>
                               </div>
                               <div className="text-center"><input className="btn btn-xl btn-warning" type="submit" value="Sign Up" /><br /><small className="text-white">Don’t worry we respect your privacy.</small></div>
                       </form>
                  </div>
                 </p>
               </div>
             </div>
          </div>



      </section>
      <footer id="footer" className="p-2 text-white text-center mt-5">
        <div className="container">
          <div className="copyright">
            &copy; Copyright <strong>Juice & Jess</strong>. All Rights Reserved
          </div>
          <div className="credits">

          </div>
        </div>
      </footer>

  </Layout>
)

export default IndexPage
